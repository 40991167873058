<script>
import Layout from '@/views/layouts/main'
import { onMounted, ref, watch } from 'vue'
import { getCustomers, transformIntoSeller } from './api'
import AppPagination from '@/components/app-pagination'
import Notify from '@/notify'
import debounce from '@/utils/debounce'
import { formatPhoneNumber } from '@/utils/phone'
import Swal from 'sweetalert2'

export default {
  setup () {
    const customersList = ref([])
    const page = ref(1)
    const pages = ref(0)
    const total = ref(0)
    const filter = ref({
      orX: null,
      created_at: {
        from: null,
        to: null
      }
    })

    const dateToISO = (date) => {
      if (date && date.length === 10) {
        return date.split('/').reverse().join('-')
      }
      return null
    }

    onMounted(() => {
      fetchCustomers()
    })

    const fetchCustomers = debounce(() => {
      const params = {
        page: page.value,
        orX: filter.value.orX,
        created_at: {
          gte: dateToISO(filter.value.created_at.from),
          lte: dateToISO(filter.value.created_at.to)
        },
        order: {
          name: 'asc'
        }
      }

      getCustomers(params).then((data) => {
        customersList.value = data.data
        pages.value = data.last_page
        total.value = data.total
      }).catch(() => {
        Notify.create({
          title: 'Falha ao carregar os clientes',
          timer: 1500
        })
      })
    }, 1000)

    const convertToSeller = (customer) => {
      Swal.fire({
        title: `Tornar ${customer.name} uma conta de vendedor?`,
        confirmButtonText: 'Confirmar',
        input: 'checkbox',
        inputValue: false,
        inputPlaceholder: 'Ativar como conta PREMIUM?'
      }).then(({ isConfirmed, value }) => {
        if (!isConfirmed) {
          return
        }

        transformIntoSeller(customer.id, {
          free_seller: value === 0
        }).then((data) => {
          fetchCustomers()
          Swal.fire(
            'Conversão efetuada!',
            `Esta conta agora é um vendedor ${data.free_seller ? 'FREE' : 'PREMIUM'}`,
            'success'
          )
        })
      })
    }

    watch(filter, () => {
      page.value = 1
      fetchCustomers()
    }, { deep: true })

    return {
      page,
      pages,
      total,
      customersList,
      fetchCustomers,
      formatPhoneNumber,
      filter,
      convertToSeller
    }
  },
  components: {
    AppPagination,
    Layout
  }
}

</script>

<template>
  <Layout :pagetitle="'Clientes'">
    <!-- end row -->
    <div class="row">
      <div class="col-lg-12">
        <div class="card mt-3">
          <div class="card-body">
            <div class="row">
              <div class="col-sm-12 col-lg-4 mb-2">
                <input
                  v-model="filter.orX"
                  type="text"
                  class="form-control"
                  placeholder="Pesquisar...."
                >
              </div>
              <div class="col-sm-12 col-lg-4 mb-2">
                <input
                  v-model="filter.created_at.from"
                  v-maska="'##/##/####'"
                  type="text"
                  class="form-control"
                  placeholder="Data de cadastro(inicial)"
                >
              </div>
              <div class="col-sm-12 col-lg-4 mb-2">
                <input
                  v-model="filter.created_at.to"
                  v-maska="'##/##/####'"
                  type="text"
                  class="form-control"
                  placeholder="Data de cadastro(final)"
                >
              </div>
            </div>
          </div>
        </div>
        <div class="card mt-3">
          <div class="card-body">
            <div class="table-responsive">
              <table
                class="table table-striped mb-4"
              >
                <thead>
                  <tr>
                    <th scope="col">
                      Nome
                    </th>
                    <th scope="col">
                      WhatsApp
                    </th>
                    <th scope="col">
                      Data cadastro
                    </th>
                    <th scope="col">
                      Pedidos
                    </th>
                    <th scope="col">
                      Números
                    </th>
                    <th scope="col">
                      E-mail
                    </th>
                    <th scope="col">
                      Promover
                    </th>
                  </tr>
                </thead>
                <tbody>
                  <tr
                    v-for="(customer, index) in customersList"
                    :key="index"
                  >
                    <td>{{ customer.name }}</td>
                    <td>{{ formatPhoneNumber(customer.whatsapp) }}</td>
                    <td>{{ customer.created_at? $d(customer.created_at, 'short') : '' }}</td>
                    <td>{{ customer.orders? customer.orders : 'N/I' }}</td>
                    <td>{{ customer.numbers? customer.numbers : 'N/I' }}</td>
                    <td>{{ customer.email }}</td>
                    <td>
                      <b-button
                        size="sm"
                        variant="primary"
                        @click="convertToSeller(customer)"
                      >
                        <b-icon icon="arrow-up" />
                        Tornar vendedor
                      </b-button>
                    </td>
                  </tr>
                </tbody>
              </table>
              <app-pagination
                v-model="page"
                class="pagination"
                :pages="pages"
                :range-size="1"
                @update:modelValue="fetchCustomers"
              />
            </div>
          </div>
        </div>
      </div>
    </div>
  </Layout>
</template>
